// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import SwitchC from "./XRTN5es_5";
import * as localizedValues from "./xSK2fQLkZ-0.js";
const SwitchCFonts = getFonts(SwitchC);

const cycleOrder = ["sb0N7n4qQ", "OTs5mmRfG"];

const variantClassNames = {OTs5mmRfG: "framer-v-jfd4b4", sb0N7n4qQ: "framer-v-8gbqx0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "sb0N7n4qQ", "Variant 2": "OTs5mmRfG"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sb0N7n4qQ", xzKcIfy7I: title ?? props.xzKcIfy7I ?? "fsfjkdsdfs"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, xzKcIfy7I, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sb0N7n4qQ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isDisplayed = () => {
if (baseVariant === "OTs5mmRfG") return false
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zynmP", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-8gbqx0", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sb0N7n4qQ"} ref={ref} style={{...style}} {...addPropertyOverrides({OTs5mmRfG: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-1l4g0u9-container"} layoutDependency={layoutDependency} layoutId={"NH7VuogMz-container"}><SwitchC height={"100%"} id={"NH7VuogMz"} layoutId={"NH7VuogMz"} style={{height: "100%", width: "100%"}} variant={"iKcZGT0A0"} width={"100%"}/></motion.div>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>fsfjkdsdfs</motion.p></React.Fragment>} className={"framer-1f27679"} layoutDependency={layoutDependency} layoutId={"euYvxlj_A"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={xzKcIfy7I} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({OTs5mmRfG: {children: <React.Fragment><motion.p>fsfjkdsdfs</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zynmP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zynmP .framer-1stqubp { display: block; }", ".framer-zynmP .framer-8gbqx0 { height: 113px; overflow: hidden; position: relative; width: 208px; }", ".framer-zynmP .framer-1l4g0u9-container { flex: none; height: 34px; left: 0px; position: absolute; top: 2px; width: 60px; }", ".framer-zynmP .framer-1f27679 { flex: none; height: 31px; left: 73px; position: absolute; top: 60px; white-space: pre-wrap; width: 85px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 113
 * @framerIntrinsicWidth 208
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"OTs5mmRfG":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"xzKcIfy7I":"title"}
 * @framerImmutableVariables false
 */
const FramerxSK2fQLkZ: React.ComponentType<Props> = withCSS(Component, css, "framer-zynmP") as typeof Component;
export default FramerxSK2fQLkZ;

FramerxSK2fQLkZ.displayName = "c";

FramerxSK2fQLkZ.defaultProps = {height: 113, width: 208};

addPropertyControls(FramerxSK2fQLkZ, {variant: {options: ["sb0N7n4qQ", "OTs5mmRfG"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, xzKcIfy7I: {defaultValue: "fsfjkdsdfs", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerxSK2fQLkZ, [...SwitchCFonts])